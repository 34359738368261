@import '../../assets/sass/styles.scss';

#rules-modal {
  .modal-content {
    min-width: 50vw;
    background-color: $background-color;
    padding: 20px;
    border-radius: 5px;
    .rules-wrapper {
      h2 {
        color: $primary;
        font-size: 2.5rem;
        font-weight: 500;
        text-align: center;
        padding-bottom: 30px;
      }
      min-height: 40vh;
    }

    .close-btn-wrapper {
      text-align: right;
    }
  }
}

ol {
  list-style: none;
  counter-reset: item;
  > li {
    counter-increment: item;
    display: table;
    &:before {
      padding-right: 10px;
      content: counters(item, '.') ' ';
      display: table-cell;
    }
  }
  li {
    margin: 10px 0;
  }
  ol {
    padding-left: 20px;
  }
  ul {
    padding-left: 20px;
    list-style: disc;
  }
  // ul li {
  //   margin-left: 20px;
  // }
  .bold {
    font-weight: 600;
  }
  table {
    border-collapse: collapse;
    td,
    th {
      padding: 3px;
    }
  }
  table,
  th,
  td {
    border: 1px solid black;
  }
}

@media screen and (max-width: 769px) {
  ol {
    list-style: none;
    counter-reset: item;
    ol {
      padding-left: 10px;
    }
    ul li {
      margin-left: 10px;
    }
  }
}
