@import '../../assets/sass/styles.scss';

.Thanks {
  margin: 0 5rem;
  min-height: 80vh;
  background-color: $primary;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    font-size: 5rem;
    line-height: 1;
  }
  .close-button {
    position: absolute;
    top: 1px;
    right: 6rem;
    font-size: 5rem;
    line-height: 3rem;
    font-weight: 200;
    color: white;
    z-index: 999;
    &:hover {
      text-decoration: none;
      transform: scale(1.05);
    }
  }
}

@media screen and (max-width: 769px) {
  .Thanks {
    margin: 0 2rem;
    h1 {
      font-size: 3.8rem;
    }
    .close-button {
      right: 3rem;
    }
  }
}
