@import '../../assets/sass/styles.scss';

.Header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo-container {
    // padding: 100px;
    img {
      max-width: 150px;
      position: absolute;
      right: 40px;
      top: 25px;
    }
  }
  .banner-container {
    .banner-image {
      justify-content: flex-start;
      align-items: center;
      display: flex;
      text-align: center;
      background-image: url('../../assets/images/banana_banner.jpg');
      background-position: center center;
      background-size: contain;
      background-color: $secondary-bg-color;
      background-repeat: no-repeat;
      width: 100vw;
      min-height: 40vh;
      h2 {
        font-size: 3rem;
        font-weight: 500;
        padding: 5rem;
        max-width: 45%;
      }
      img {
        // max-height: 60vh;
        position: relative;
        width: 100vw;
      }
    }
  }
  .columns {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .description-container {
      .campaign-description {
        font-size: 1.5rem;
        font-weight: 500;
        color: $primary;
        margin: 20px 0 20px 0;
        line-height: 1;
        text-align: start;
        //   max-width: 60%;
      }
      .campaign-period {
        text-align: start;
        font-size: 1.2rem;
      }
    }
    .nav-container {
      margin: 20px auto;
      text-align: center;
      .button {
        margin: 10px;
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .Header {
    .logo-container {
      background-color: $secondary-bg-color;
      padding: 20px;
      width: 100%;
      text-align: center;
      img {
        position: relative;
        right: 0px;
        top: 0px;
      }
    }
    .banner-container {
      .banner-image {
        justify-content: center;
        align-items: flex-start;
        background-size: cover;
        h2 {
          font-size: 1.5rem;
          font-weight: 500;
          padding: 0;
          max-width: 100%;
        }
      }
    }
    .columns {
      .description-container {
        .campaign-description {
          font-size: 1.2rem;
          padding: 0 20px;
          text-align: center;
        }
        .campaign-period {
          text-align: center;
          font-size: 1rem;
        }
      }
      .nav-container {
        margin: 20px auto;
        text-align: center;
        .button {
          width: 90vw;
        }
      }
    }
  }
}
