@import '../../assets/sass/styles.scss';
.Main {
  background-color: $background-color;
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    .prizes {
      text-align: center;
      padding: 3rem 0;
      img {
        max-height: 35vh;
      }
    }
  }

  .footer {
    background-color: $background-accent;
    .container {
      text-align: end;
    }
  }
}

@media (min-width: 992px) and (max-width: 1440px) {
  .Main {
    .content {
      .prizes {
        img {
          max-height: 60vh;
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .Main {
    .content {
      margin-top: 2rem;
      .prizes {
        padding: 0;
      }
    }
  }
}
